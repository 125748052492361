export enum ArubaState {
  Available = 'available',
  Loading = 'loading',
  Unavailable = 'unavailable',
}

export function useAruba() {
  const state = useState<ArubaState | undefined>('aruba.state');

  const wifiLogin = useRuntimeConfig().public.wifiLogin;

  watch(() => state.value, () => {
    if (state.value || process.server) {
      return;
    }

    state.value = ArubaState.Loading;

    fetch(wifiLogin, {method: 'POST'})
      .then(() => state.value = ArubaState.Available)
      .catch(() => state.value = ArubaState.Unavailable);
  }, {
    immediate: true,
  });

  return {
    isAvailable: computed(() => state.value !== ArubaState.Unavailable),
    isLoading: computed(() => !state.value || state.value === ArubaState.Loading),

    login() {
      const form = document.createElement('form');

      form.setAttribute('action', wifiLogin);
      form.setAttribute('autocomplete', 'off');
      form.setAttribute('method', 'POST');
      form.innerHTML = `
  <input type="hidden" name="cmd" value="authenticate">
  <input type="hidden" name="user" value="getwifi">
  <input type="hidden" name="password" value="getwifi">
  <input type="submit">
`;
      document.body.appendChild(form);

      form.submit();
      form.remove();
    },
  };
}
